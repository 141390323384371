/** Guide for Font Awesome V6
 * 1. Go to https://fontawesome.com/ search for the icon you need
 * 2. Click on it and under the tab 'VUE', select 'Array syntax'
 * 3. The import name should be able to construct as below example:
 *    <font-awesome-icon :icon="['fas', 'address-book']" />
 *    import name: faAddressBook
 * 4. Paste the import name below based on the type (fas or far)
 *    fas = free-solid-svg-icons
 *    far = free-regular-svg-icons
 * 4.1 If same import name existed but from another location, import as this: faCircle as fasCircle
 * 5. Do not forget to add the name in the icons array
 * 6. Save and you should be able to use the new icon
 */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

// Import icons
// fas
import {
    faQuestion, faQuestionCircle, faXmark, faCertificate, faArrowsRotate, faImage, faFill, faFont, faBookmark, faChevronDown, faBookmark as fasBookmark, faEye, faGear, faVideo, faCode, faTriangleExclamation, faMagnifyingGlass, faSliders, faTimes, faPlus, faCircleQuestion, faCaretUp, faInfo, faThumbtack, faSearch, faChartArea, faChevronLeft, faLongArrowAltUp, faLongArrowAltDown, faSlash, faCircleNotch, faCheck, faCheckCircle, faExclamationCircle, faExclamationTriangle, faCircle as fasCircle, faAngleUp, faAngleDown, faUpload, faTrash, faCaretDown, faInfoCircle, faSortAmountAsc, faBars, faPencilAlt, faDownload, faAngleDoubleUp, faSpinner, faLink, faEyeSlash, faEyeDropper, faEnvelope, faEllipsisVertical, faCalendarDays, faArrowRightArrowLeft, faCircleXmark, faToggleOn, faToggleOff, faTag, faShare, faCopy, faArrowRight, faRuler, faPaintRoller, faImages, faPalette, faTable, faBoxOpen
} from '@fortawesome/free-solid-svg-icons';

// far
import { faCircle, faCircleCheck, faBookmark as farBookmark, faFile, faFileVideo, faCircleQuestion as farCircleQuestion } from '@fortawesome/free-regular-svg-icons';

const icons = [
    faImage, faFill, faFont, faBookmark, faChevronDown, faCheck, faCircleCheck, faCircle, fasCircle, fasBookmark, farBookmark, faArrowsRotate, faQuestion, faQuestionCircle, faXmark, faCertificate, faEye, faVideo, faCode, faTriangleExclamation, faCircle, faGear, faMagnifyingGlass, faSliders, faTimes, faPlus, faCircleQuestion, faCaretUp, faInfo, faThumbtack, faSearch, faChartArea, faCircleNotch, faChevronLeft, faLongArrowAltUp, faLongArrowAltDown, faSlash, faCheckCircle, faExclamationCircle, faExclamationTriangle, faAngleUp, faAngleDown, faUpload, faTrash, faCaretDown, faInfoCircle, faSortAmountAsc, faBars, faPencilAlt, faDownload, faAngleDoubleUp, faFile, faSpinner, faFileVideo, faLink, farCircleQuestion, faEyeSlash, faEyeDropper, faEnvelope, faEllipsisVertical, faCalendarDays, faArrowRightArrowLeft, faCircleXmark, faToggleOn, faToggleOff, faTag, faShare, faCopy, faArrowRight, faRuler, faPaintRoller, faImages, faPalette, faTable, faBoxOpen
];

// Register the icons in library
library.add(...icons);

export default FontAwesomeIcon;

