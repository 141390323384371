import isURL from 'validator/lib/isURL'

// TODO: What does this do?  Maybe it should go away...
export function domDecoder (str) {
                // Make sure string is passed
                if (typeof str === 'string' || str instanceof String) {
                    let parser = new DOMParser();
                    let dom = parser.parseFromString('<!doctype html><body>' + str, 'text/html');
                    return dom.body.textContent;
                    }
                else {
                    return str
                }
                }


export function validateUrl(url, addHttps = true) {
    // Prepend https:// if no scheme provided
    if (addHttps && !url.startsWith('https://') && !url.startsWith('http://') && !url.startsWith('ftp://')) {
        url = 'https://' + url;
    }

    if (isURL(url, {
        protocols: ['http', 'https'],
        require_tld: false // Top-Level Domain

    })) {
        return { valid: true };
    } else {
        return {
            valid: false,
            error: "Please provide a valid URL."
        };
    }
}